body{margin:0px!important; padding:0px; font-family: 'Poppins', sans-serif!important;
 font-size:16px!important;font-weight:normal!important;

 
 }

a{text-decoration:none;color: #000;}
p{padding:0px 0px 10px 0px; margin:0px; text-align:justify;color:#423f3b;}
h1,h2,h3,h4,h6{padding:0px; margin:0px 0px 10px 0px; color:#423f3b;font-weight:400}
h1{font-size:29px}
h2{font-size:27px}
h3{font-size:25px}
h4{font-size:22px;}h5{font-size:20px;}
.clear{clear:both;}
.iconvertical{vertical-align:middle}
.aligncentr{text-align:center}
.content{margin:15px 0}
.alignright{text-align:right}
.floatright{float:right}
.main{width:94%;margin:auto}
.cusrsoor{cursor:pointer}
.searchbtn{
margin-left:1%;	
margin-top:29px;
}
.MuiTypography-root{
    content: "\a";
    white-space: pre;width: 300px;
}
.MuiButton-root{
color:#fff;
}
[role=menu] .MuiMenu-paper{
	top:68px !important;
	left:1230px !important
}
.catback{margin-top:10px;cursor:pointer}
.headerleft {
    float: left;
    width: 30%;
    color: #fff;
    margin: 5px 0px 0px 3%;
}
.transform-component-module_wrapper__SPB86{width:350px!important}
.headerrt{
	float: right;
    width: 65%;
}
.headerleft h1{
	font-weight:400;
color:#e2ecdf;
}
.salessec{
	height:120px;padding:10px 3%
}
.mastheadright img{
	max-width:100%;
}
.css-zxdg2z{
	padding:0px !important;
}
	.MuiTable-root .MuiTableCell-root{    padding: 10px 5px!important;}
.mastheadleft img{
max-width:100%;

   
}
.catsec .catcount p{width:auto!important;padding: 5px 10px!important;}
.mastheadleft {
    float: left;
    width: 20%;
	text-align:center;
	background-color:#fff;border-bottom: 1px solid #efefef;
}
.mastheadright{
	 float: right;
    width: 80%;
	background-color:#328859;
	padding:10px 0px 0px 0px;
}
.mastheadright .css-i4bv87-MuiSvgIcon-root{
	color:#fff;
}
.mastheadright ul{
	padding:0px;
	margin:0px 5% 0px 0px;
	text-align:right;
}
.mastheadright li{
	display:inline-block;
	text-align:right;cursor:pointer;
	margin:0px 2%;
	vertical-align:top;
}
#root{
    background: #f0f0f0;
}
.welcometext {
    padding: 0px 0px 0px 0px ! important;
   color:#e2ecdf;
   font-size:14px;
}
.welcometxt {
    padding: 0px 0px 0px 0px ! important;
   color:#fff;
   font-size:18px;
}
.footer{
	background-color:#ed8143;
}
.copyrighttext{
text-align:center;
color:#fff;
padding:7px 0px;	
}


.arrow {
  color: #fff;  
  margin: 1em 0;
}
.arrow::after {
  display: inline-block;
  padding-left: 6px;
  content: "➞";
  transition: transform 0.3s ease-out;
}
.arrow:hover {
  color: #fff;
 
}
.arrow:hover::after {
  transform: translateX(4px);
}
.innericons li{
	border:0px !important;
	margin: 0px !important;cursor:pointer;
    width: 33.3% !important;
    border-radius: 0px !important;
	height:auto !important;float:left;list-style:none;padding:2px 0
}
.carticons,.Productsection ul{margin:0px;padding:0px;}
.carticons li{border:0px !important;
	margin: 0px !important;cursor:pointer;
    width: 50% !important;
    border-radius: 0px !important;padding:2px 0;
	height:auto !important;float:left;list-style:none}
	.carticons li:first-child{
	background-color:#3cba6c;
	border-radius: 0px 0px 0px 20px !important;
}
.nostock li{width:100%!important}.nostock li:first-child{background-color:#318959;
	border-radius: 0px 0px 20px 20px !important;}
	.nostock {
    margin-top: 70px !important;
}
.prodimage{float:left;width:40%}
.prodimage img{width:100%}
.productdetails{float:right;width:55%}
.owltest li img{width:100%}
.prodcart .MuiInputBase-input{padding:5px 10px!important;text-align:center}
.carticons li:nth-child(2){
		background-color:#318959;border-radius: 0px 0px 20px 0px !important;
}.carticons li p,.innericons li p{display:none;font-size:15px}
li.gridprod:hover .carticons li img{display:none}
li.gridprod:hover .carticons li p{display:block;    padding: 3px 0;}
li.gridprod:hover .innericons li img{display:none}
li.gridprod:hover .innericons li p{display:block;    padding: 3px 0;}
.prodimg img{height:100px;object-fit:contain;width:100%}
.prodimg{margin-top:10px}
.cusordername p{font-size:13px;padding:0px}.cusordername h3{font-weight:bold}
.prodheading{float:left;width:40%}.prodheading h2{border-left:10px solid #f57328;margin:0px;padding:0 0 0 10px}
.prodcol1{display:table-cell;width:40%;vertical-align:top;}
.prodcol2{display:table-cell;width:10%;vertical-align:middle;}
.prodcol3{display:table-cell;width:10%;vertical-align:middle;}
.prodcol4{display:table-cell;width:30%;vertical-align:middle;}
.listprodrow{display:table-row;width:100%;border-bottom:20px solid transparent}
.listtabrow{display:table;width:100%;margin:30px 0}
.prodlistimg{float:left;width:40%;text-align:left}
.prodlistheading{float:right;width:59%;margin-top:15px}
.prodimg h2,.prodlistheading h2{font-size:20px;height:60px;overflow:hidden}
.prodinfo h4{font-weight:600}
.listquickview{border-radius:10px;width:35px;vertical-align:middle}
.innericons li:first-child{
	background-color:#3cba6c;
	border-radius: 0px 0px 0px 20px !important;
}
.innericons li:nth-child(2){
		background-color:#ec8143;
}
.innericons li:last-child{
	background-color:#318959;
	border-radius: 0px 0px 20px 0px !important;
}
.innericons img,.carticons img{
	width:30px;vertical-align:middle
}
.dashboards .MuiTextField-root{
	width:40px;
	
}
.MuiTextField-root{margin:0px!important;}
.MuiInputBase-input{
	padding:8.5px 10px!important;
}.dashboard ul{margin:0px;padding:0px}
.dashboard li {
    display: inline-block;
    width: 18%;
    margin-left: 2.5%;
    margin-right: 2.5%;
    background-color: #fff;
    margin-top: 30px;
    margin-bottom: 10px;
	text-align:center;
	vertical-align:top;
	padding:20px 0px;
}
.dashboards ul{
	padding:0px;
	margin:0px;
	width:100%;
	
	text-align:left;
}
.dashboardsales ul{
	padding:0px;
	margin:10px 0 0 0;
	width:100%;
	
	
}
p.nextbtn {
    background-color: #39bc6e;
    display: inline-block;
    color: #fff;
    padding: 5px 10px!important;cursor:pointer;
	border-radius:6px;float:right;
}
.dashboards li {
    display: inline-block;
    width: 20%;
    margin:20px 1%;
    
	text-align:center;
	vertical-align:top;
	border: 1px solid;
    border-radius: 20px;
}

  .bgclrs{
	background-color:#40be73;	
}
 .bgclr{
	background-color:#717171;	
}
.dashboardsales h4{
	font-size:17px;
	color:#4d4b4b;
	font-weight:bold;margin:0px
}.dashboardsales p{margin:0px;padding:0px;}
.dashboardsales h3{
		font-size:15px;
	color:#f2813c;
	font-weight:normal;margin:0px
}
.dashboardsales .css-i4bv87-MuiSvgIcon-root{

	vertical-align:middle;
}
.dashboards li p{
	text-align:center;

	color:#fff;
	border-radius: 20px 20px 0px 0px;
	padding:5px 0px 5px;
}
.dashboards {
    margin: 50px 0px;
}
.dashboard h2.headertext,.dashboards h2.headertext{
	border-left: 18px solid #f57328;
    padding-left: 10px;
	font-size: 29px;
	font-weight:500;

}
.dashboard li p{
	text-align:center;
	margin-top: -15px;
   
}
.dashboard h3{
	font-size:50px;
	margin-bottom:0px;font-weight:400
}
.counts {
    background-color: #39bc6e !important;
    color: #fff;
}
.counts p a{
	  color: #fff;
	  text-transform:uppercase;
}
.count .css-i4bv87-MuiSvgIcon-root{
	height:35px;
	width:40px;
}

.dashboardsales li{
	float:left;list-style:none;
	width:18%;
	margin:20px 1%;
	vertical-align:top;    vertical-align: top;
    background-color: #fff;
    -webkit-box-shadow: -2px 0px 19px -2px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: -2px 0px 19px -2px rgba(0, 0, 0, 0.75);
    box-shadow: -2px 0px 19px -2px rgba(0, 0, 0, 0.25);
    border-radius: 12px;

}
.float-tw {
    top: 145px;
}.fl-fl {
    background: #39bc6e;
    text-transform: uppercase;
    letter-spacing: 3px;
    padding: 3px 3px 3px 10px;
    width: 190px;
    position: fixed;
    right: -160px;
    z-index: 1000;
    font: normal normal 10px Arial;
    -webkit-transition: all .25s ease;
    -moz-transition: all .25s ease;
    -ms-transition: all .25s ease;
    -o-transition: all .25s ease;
    transition: all .25s ease;
	-webkit-border-top-left-radius: 15px;
-webkit-border-bottom-left-radius: 15px;
-moz-border-radius-topleft: 15px;
-moz-border-radius-bottomleft: 15px;
border-top-left-radius: 25px;
border-bottom-left-radius: 25px;
	
	
}.fl-fl a {
    color: #fff !important;
    text-decoration: none;
    text-align: center;
    line-height: 43px!important;
    vertical-align: top!important;
}.fl-fl:hover {
    right: 0;
}
.searchsec fieldset{border:0px!important}
.searchsec .MuiAutocomplete-inputRoot{padding:0px!important}
.searchsec .MuiAutocomplete-root{float: right;
    width: 90%;}
.searchsec{margin-bottom:10px}
.searchsec .MuiTextField-root{
	width:80%;
	border-radius:30px;
	background-color:#fff;border:1px solid #959595;margin:0px 0% 0px 3%;
	padding:0px 10px;
}
.salessearchsec 
.MuiAutocomplete-root{
	width:30%;
	border-radius:30px;
	background-color:#fff;border:1px solid #959595;margin:0px 0% 0px 0%;
	padding:3px 10px;
}
.salessearchsec  .MuiOutlinedInput-notchedOutline{border:0px}
.salessearchsec .MuiAutocomplete-inputRoot{padding:0px!important}
.searchsec .MuiTextField-root:hover:not(.Mui-disabled, .Mui-error):before,
.searchsec .MuiInputBase-formControl:before,
.salessearchsec .MuiInputBase-formControl:before
{
	border-bottom:0px !important;
}
.searchsec .MuiTextField-root:after{
	border-bottom:0px !important;
}
.mainmenus .MuiListItem-padding{
	padding-left:9px;
	padding-right:0px;
}
.counter{
	height:30px;
	width:45px;
	margin:5px 0px 20px;
}
.counters{
	
	width:45px;
	margin:5px 0px 20px;
}
.prodcart {
   margin-bottom:30px;
}
span.cartplus {
    padding-left: 31px;
    position: absolute;
	z-index: 9999;
}
span.cartminus {
    position: absolute;
    
    margin-top: 30px;
    margin-left: -14px;
}
.smartbasket{
	width:70%;
}
.smartbasket .counter{
    height: 30px;
    width: 45px;
    margin: 5px 0px 20px;
   
    border-color: #eee;
    border: 2px solid #8e8e8e;
    background-color: #efeaea;
}
.smartbasket .counter:focus-visible{
ouline:0px !important;
}
.smartbasket ul{
	padding:0px;
	margin:0px;
}
.smartbasket li{
	display:inline-block;
	vertical-align:middle;
	width:18%;
	margin:10px 0.5%;
}
.btndelete{background-color: #ed8241;
    color: #fff;
  
    padding: 7px 10px;
    display: inline-block;vertical-align:middle;
	border-radius: 10px 10px 10px 10px;}
.addtocart .btnupdate {
   background-color: #40be73;
    color: #fff;
    vertical-align: middle;
    padding: 7px 10px;
    display: inline-block;
	border-radius: 10px 10px 10px 10px;
}
.addtocart .smartadd {
        background-color: #40be73;
    color: #fff;
    vertical-align: middle;
    padding: 3px 10px 4px 10px;
    display: inline-block;
    border-radius: 10px 0px 0px 10px;
}
.addtocart .smartimg{
	background-color: #318959;
padding: 3px 7px 5px 4px;
    border-radius: 0px 10px 10px 0px;

}
.addtocart img,.btndelete img{
	width: 22px;
    vertical-align: middle;
}
span.validation {
    color: #c82320;
}

.contentmain{
width:90%;
margin:30px auto 30px;
}

.stadrp{
width:94%!important;}
.myaddrs .MuiFormGroup-root{display:block}
.deleadrs{display:inline-block;    vertical-align: middle;
    padding-top: 5px;}
.setingdiv .css-bshv44-MuiButtonBase-root-MuiListItem-root{
	display:block;
	padding-left:0px !important;
}
.setingdiv .css-cveggr-MuiListItemIcon-root{
	display:block;
}
.setingdiv .css-cveggr-MuiListItemIcon-root{
	display:block;
}
.contentinner {
    width: 500px;
    margin: auto;
}
/*Login */
.logindiv h1{
font-size:50px;font-weight:400
}
.loginmain{
	background-image:url(../images/bg.jpg);
	background-repeat:no-repeat;
	background-size:100% 100%;
	padding:70px 0;
	background-position:left bottom;
	height: 100vh;
}
.leftbandlog-col img{
	max-width:300px;
}
.mainscreen-row{
	padding:40px 0px 80px;
}
.leftbandlog-col{
	float:left;
	width:30%;
	text-align:right;
	margin-left:6%;margin-top:40px;
}
.contentseclog-col {
    float:right;
	width:50%;
	margin-right:5%;
}
.logindiv {
   padding:0 5%;width:60%;margin:auto;
    text-align: center;
}
.searchpart ul{margin:0px;padding:0px}
.searchpart li{display:inline-block;margin:0px 1%;vertical-align:top}
.logindiv .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked{color: #d24219!important}
.frmPrimarybtn {
    background-color: #e77f42 !important;
    width: 100%;
    border-radius: 10px !important;
}
.catsec ul{
	padding:0px;
	margin:0px;
}
.catsec li{
	display:inline-block;
	width:27%;cursor:pointer;
	background-color:#f2f2f2;vertical-align:top;
	
	margin:15px 2%;
	text-align: center;
	border-radius: 20px;
	border:1px solid #efeaea;
}.catboxpad h4{font-size:18px;height:46px}
.catboxpad{padding:10px 2%}.catboxpad img{height:100px;object-fit:contain;width:100%}
.catsec li:hover{
	border:1px solid #328859;
	
}
.catsec li:hover .catcount p{
	background-color:#328859;	
}
.categorysection{
	width:40%;
	 display:table-cell;background-color:#fff;vertical-align:top
}
.productrow{display:table-row;width:100%}
.producttabrow{display:table;width:100%}
.catinnerpad{margin:30px 2% 10px 6%}
.Productsection {
    width: 60%;
     display:table-cell;
}
.admindialoguform ul,.dialoguebtn ul,.adminform ul{margin:0px;padding:0px}
.adminform li{float:left;width:30%;margin:5px 1%;list-style:none}
.admingal li{width:22%;}
.adminform li .MuiNativeSelect-select{    padding: 8.5px 14px;}
.admindialoguform li{list-style:none;margin:5px 0}
.admindialoguform li .MuiTextField-root,.adminform li .MuiTextField-root{width:100%}
.dialoguebtn li{float:left;list-style:none}
.dialoguebtn li:last-child{float:right;}
.address-div{margin-top:20px}
.prodinnerpad{margin:30px 6% 10px 2%}
.addtocart{
cursor:pointer;font-size:15px;    display: inline-block;
    vertical-align: middle;padding:0px;
}.btndelete{cursor:pointer;font-size:15px;  }
.catcount {
    text-align: right;
    margin-top: -30px;
}
.cartcount{    position: absolute;
    margin-top: -5px;
    margin-left: -10px;}
.catcount p,.cartcount{
	background-color: #f57328;
    display: inline-block;
width: 24px;
    height: 24px;
    text-align: center;
    border-radius: 50%;
	color:#fff;
}.catcount p{padding:0px}
.nettot{
	color:#ec8148;font-size:24px
}
.orderbtn{ background: #3dbe6e;
    display: inline-block;
    padding: 2px 10px;
    vertical-align: middle;font-size:14px;
    color: #fff;
   cursor:pointer}
.primarybtn {
    background-color: #3dbe6e!important;
    display: inline-block!important;
    padding: 2px 10px!important;
    vertical-align: middle;
    color: #fff;
    border-radius: 10px;cursor:pointer
}.primarybtn a{color:#FFF}
.reodrerbtn {
    background-color: #3dbe6e!important;
    display: inline-block;
    padding: 1px 10px;
    vertical-align: middle;
    color: #fff;font-size:14px;
    border-radius: 8px;cursor:pointer
}.reodrerbtn a{color:#FFF}
.secondarybtn {
    background-color: #e97c41!important;
    display: inline-block!important;
    padding: 2px 10px 2px!important;
    vertical-align: middle;
    color: #fff;
    border-radius: 10px;
	margin:0px;;cursor:pointer
}
.generalbtn{ background-color: #828784!important;
    display: inline-block!important;
    padding: 2px 10px 2px!important;
    vertical-align: middle;
    color: #fff!important;border-radius:4px!important;
    border-radius: 10px;
	margin:10px 0px;;cursor:pointer}
	.primarybtn svg,.secondarybtn svg,.generalbtn svg{vertical-align:middle}
.searchsec p img{
	display:inline-block;
	vertical-align:middle;
	    margin-top: 30px;
    margin-right: 30px;
    cursor: pointer;
}
.prodcount {
	
       
    padding: 2px 10px;
    color: #fff;
     margin-top: -15px; 
    margin-left: 5%;
    font-size: 12px;
    border-radius: 5px;
    position: absolute;
}
.prodcount .bgclr{
	background-color: #717171;
    display: inline-block;
        padding: 5px 10px;
    color: #fff;
    margin-right: -47px;
    margin-left: 54px;
    font-size: 13px;
	border-radius:5px;
}
.searchlrt .css-i4bv87-MuiSvgIcon-root:active,.searchlrt .css-i4bv87-MuiSvgIcon-root:focus{
	color:#328859 !important;
}
.innericons,.products ul{
padding:0px;margin:0px;
}
.prodinfo{
	height:138px;
}
li.products {
    display: inline-block;
    width: 30%;
    margin:20px 1%;  
    
	text-align:center;
	vertical-align:top;
	border: 1px solid;
    border-radius: 20px;
}
li.products  p{
	text-align:center;

	color:#fff;
	border-radius: 20px 20px 0px 0px;
	padding:5px 0px 5px;
}
.searchlrt {
    float: right;
    width: 15%;
    margin: 10px 0px 0px;
}.searchlrt p{text-align:right}
.searchlft {
    float: right;
    width: 45%;
} .searchlrt svg{width:1.6rem!important}
.searchlrt .css-i4bv87-MuiSvgIcon-root{
	width:2rem;
	height:2rem;
}
.orderform ul{margin:0px;padding:0px}
.orderform li{float:left;width:30%;list-style:none;margin:5px 1%}
.orderform li p{padding-bottom:4px}.orderform li .MuiTextField-root{margin-top:0px}
.proddetails h2{
	border-left: 12px solid #f57328;
    padding-left: 10px;
    font-size: 24px;
    font-weight: 500;
    color: green;
    padding: 0px 0 0 8px;
   
line-height: 25px;

}.ordertab table {width: 50%;}
.myordertab table{
    width: 40%;
}
.myordertab #addr0,.ordertab #addr0{    background-color: #fff;
    margin: 15px 0;border-radius: 13px;
    /* border: 10px solid #f0f0f0; */
    display: block;}
	.myordertab h4,.ordertab h4{font-weight:bold;margin-bottom:0px}
	.myordertab h3,.ordertab h3{font-size:20px;margin-bottom:0px}.myordertab td,.ordertab  td{vertical-align:middle}
	.myorderheading h2{float:left;width:40%}
	.myorderheading p{float:left}
	.smartbasket td{
	padding:10px 0
}
.prodimg img{cursor:pointer}
.catable tr td:first-child img{width:100%;height:90px;object-fit:cover}
.cartprodimage{float:left;width:20%}
.cartprodtitle{float:left;width:80%}
.cartprodqty{float:left;width:20%;margin-right:5%}
.cartprodqtybtn{float:left;width:70%}
.cartcounters{width:90%;margin:5px }

.icon-submenu .MuiMenu-paper{top: 50px !important;
    left: unset !important;
    right: 130px !important;}
@media(max-width:999px) {
	.contentmain{width:96%}
	li.products {
    display: inline-block;
    width: 47%;
   
}
.dashboards li {
    display: inline-block;
width: 23%;}
	.dashboardsales li{width:23%}.icon-submenu .MuiMenu-paper{
   
    right: 90px !important;
}
}
@media(max-width:767px) {
	.dashboardsales li{width:30%}
	.prodinnerpad{    margin: 10px 2% 10px 1%;}
	.catinnerpad{margin: 10px 1% 10px 2%;}
.mastheadleft,.mastheadright{float:none;width:100%}	
	.catsec li { width: 47%;margin: 10px 1%;}
	.dashboard li{width:28%}
	.dashboards li {
    display: inline-block;
width: 45%;}.dashboards ul {text-align:center}
.cartprodimage,.cartprodtitle,.cartprodqty,.cartprodqtybtn{float:none;width:100%}
.catable tr td:first-child img{width:70px;height:70px;}
.catable{width:96%!important}
.icon-submenu .MuiMenu-paper{top: 120px !important;
   
    right: 130px !important;}.prodcol4,.listprodrow{display:block;width:100%}
}
@media(max-width:650px) {
	.catinnerpad{margin: 0px 1% 10px 1%;}
	.prodinnerpad{margin: 30px 1% 10px 1%;}
	.catsec ul{margin-top:30px}
	.catsec li { width: 30%;margin: 10px 1%;}
	.categorysection,.Productsection{display:block;width:100%}
	.headerrt,.headerleft{float:none;width:100%}	
	
	.mastheadright ul{text-align:center}
	.headerleft{text-align:center;margin-left:0px}
	.contentseclog-col {
    width: 100%;
    
}

.icon-submenu .MuiMenu-paper{top: 110px !important;
   
    right: 35% !important;}
.catable h4 {
    font-size: 18px;
}
.orderform li,.adminform li{width:47%}
.dashboard li p {
    text-align: center;
 margin-top: 0px; 
}
.contentinner {
    width: 90% !important;
    margin: auto;
}
.mastheadright img {
   
    width: 30px;
}
.smartbasket{
	width:100%;
}
.dashboard li{width:29%;margin:1.5%}
.prodheading{float:none;width:100%}
.searchlrt{float:right;width:30%}
.searchlft{float:left;width:70%}
}
@media(max-width:450px) {
	
	.dashboardsales li{width:48%}
		li.products {
    display: inline-block;
    width: 95%;margin:20px 2%
   
}.catable h4 {
    font-size: 16px;font-weight:normal
}.catsec li {width:45%}.orderform li,.adminform li{width:97%}.dashboards li {
  
width: 97%;}.prodimg h2, .prodlistheading h2{ height:auto; overflow: initial;}
.dashboard li{width:48%;margin:1%}
.counts{width:80%!important;margin:auto!important;display: block !important;}
.dashboard h3{font-size: 30px;}.icon-submenu .MuiMenu-paper{top: 110px !important;
   
    right: 28% !important;}
}

/*anjali */
.Letterbox ul {
    margin: 0px;
    padding: 0px;
    list-style-type: none;
}


.Letterbox li {
    display: inline-block;
   
    background-color: #40be73;
    color: white;
        padding: 3px 10px;
		    border-right: 2px solid #ffffff;
}


.hover-effect {
  cursor: pointer;
  padding: 5px; 
  transition: background-color 0.3s ease; 
}

.hover-effect:hover {
  background-color: white;color:black;
      
}

.reactcanvas ._1Lxpd{
	    height: 130px!important;
}

.reactcanvas ._15Nzf {

    margin-top: -300px!important;
}

.signature-canvas{border:1px solid black;    background-color: white;}

span.refreshicon {
    margin-top: -20px;
    position: relative;
  cursor:pointer;
    top: -31px;
   
    left: 378px;
}